// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/SplashScreen.css */
.splash-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: rgb(0, 0, 0);
    font-size: 2em;
    animation: fadeInOut 2s infinite; /* 2s for a full cycle (1s fade in + 1s fade out) */
  }
  
  .splash-logo {
    width: 100px;
    height: 100px;
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SplashScreen/style.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,gCAAgC,EAAE,mDAAmD;EACvF;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":["/* src/SplashScreen.css */\n.splash-screen {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    color: rgb(0, 0, 0);\n    font-size: 2em;\n    animation: fadeInOut 2s infinite; /* 2s for a full cycle (1s fade in + 1s fade out) */\n  }\n  \n  .splash-logo {\n    width: 100px;\n    height: 100px;\n  }\n  \n  @keyframes fadeInOut {\n    0%, 100% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 1;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
