import "./index.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import React, { Suspense, useEffect, useState } from "react";

import App from "./App";
import { AuthProvider } from "contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import SplashScreen from "components/SplashScreen";
import reportWebVitals from "./reportWebVitals";
// src/index.js or src/App.js



const root = ReactDOM.createRoot(document.getElementById("root"));

const SplashScreenWrapper = ({ children }) => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000); // Minimum 2 seconds

    return () => clearTimeout(timer);
  }, []);

  if (showSplash) {
    return <SplashScreen />;
  }

  return children;
};

root.render(
  <React.StrictMode>
    <Suspense fallback={SplashScreen}>
      <SplashScreenWrapper>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SplashScreenWrapper>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
