// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tw-footer {
    height: 312px;
    background-color: #25282B;
    padding: 48px 72px 48px 72px;
    color: white;
    display: grid;
    align-content: space-between
}

.tw-footer .menu {
    display: flex;
    justify-content: space-between;
}

.tw-footer .menu .header {
    font-weight: 600;
    margin-bottom: 24px;
    cursor: unset;
}

.tw-footer .menu p {
    cursor: pointer;
}

.tw-footer .home {
    display: grid;
}

.tw-footer .tw-cr {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid white;
    padding-top: 24px;
}

.tw-footer .tw-cr .right-section {
    display: flex;
    gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,4BAA4B;IAC5B,YAAY;IACZ,aAAa;IACb;AACJ;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,2BAA2B;IAC3B,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ","sourcesContent":[".tw-footer {\n    height: 312px;\n    background-color: #25282B;\n    padding: 48px 72px 48px 72px;\n    color: white;\n    display: grid;\n    align-content: space-between\n}\n\n.tw-footer .menu {\n    display: flex;\n    justify-content: space-between;\n}\n\n.tw-footer .menu .header {\n    font-weight: 600;\n    margin-bottom: 24px;\n    cursor: unset;\n}\n\n.tw-footer .menu p {\n    cursor: pointer;\n}\n\n.tw-footer .home {\n    display: grid;\n}\n\n.tw-footer .tw-cr {\n    display: flex;\n    justify-content: space-between;\n    border-top: 2px solid white;\n    padding-top: 24px;\n}\n\n.tw-footer .tw-cr .right-section {\n    display: flex;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
