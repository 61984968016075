// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-back-icon {
    cursor: pointer;
}

.label-back {
    font-size: 16px;
    font-weight: 400;
    color:  #535353;
}

.space {
    cursor: pointer;
    display: flex;
    gap: 4px;
    align-items: center
}

.space:focus, .space:hover {
    opacity: 75%;
}

.title-font {
    font-size: 28px;
    font-weight: 600;
    color: #000000;
}

.title-red {
    color: red
}

.login-wrapper {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
}

.login-header {
    display: grid;
    justify-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.login-content {
    width: 80%;
}

.login-content .ant-form-item-label {
    font-size: 14px;
    font-weight: 600;
}

.login-content .ant-btn-primary {
    background: #FF0000;
}
.login-content .ant-btn-primary span {
    font-size: 16px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,QAAQ;IACR;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".login-back-icon {\n    cursor: pointer;\n}\n\n.label-back {\n    font-size: 16px;\n    font-weight: 400;\n    color:  #535353;\n}\n\n.space {\n    cursor: pointer;\n    display: flex;\n    gap: 4px;\n    align-items: center\n}\n\n.space:focus, .space:hover {\n    opacity: 75%;\n}\n\n.title-font {\n    font-size: 28px;\n    font-weight: 600;\n    color: #000000;\n}\n\n.title-red {\n    color: red\n}\n\n.login-wrapper {\n    display: flex;\n    height: 90vh;\n    align-items: center;\n    justify-content: center;\n}\n\n.login-header {\n    display: grid;\n    justify-items: center;\n    gap: 16px;\n    margin-bottom: 16px;\n}\n\n.login-content {\n    width: 80%;\n}\n\n.login-content .ant-form-item-label {\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.login-content .ant-btn-primary {\n    background: #FF0000;\n}\n.login-content .ant-btn-primary span {\n    font-size: 16px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
